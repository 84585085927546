$primary: #0D4B79;
$primary-dark: #1669bb;
$primary-light: #2083e4;

$accent: #7CB342;
$accent-dark: #5c8c31;
$accent-light: #74b23f;

$warn: #E67E22;
$warn-dark: #CD4800;
$warn-light: #FF5A00;

$white: #FFFFFF;
$white-dark: #E6E6E6; 
$white-light: #F2F2F2; 
$black: #212121;
$black-dark: #141414;
$black-light: #2E2E2E;

$primary-gradient: linear-gradient(90deg, rgba(11,61,98,1) 0%, rgba(13,75,121,1) 35%, rgba(15,89,144,1) 93%);
