.form-row {
    .form-2 {
        margin-right: 0;
    }

    .form-3 {
        margin-right: 0;
    }
}

.form-1, .form-2, .form-3, .form-3-only, .form-auto {
    width: 100%;
}

.form-tel {
    display: inline-block;
    position: relative;
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
    .form-row {
        .form-2 {
            margin-right: 0.75rem;
    
            &:last-child {
                margin-right: 0;
            }
        }
    
        .form-3 {
            margin-right: 0.75rem;
    
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .form-2 {
        width: 48%;
    }

    .form-3 {
        width: 31.7%;
    }

    .form-3-only {
        width: 48%;
    }

    .form-auto {
        width: 63.4%;
    }
}

@media (min-width: 992px) {
    .form-2 {
        width: 49%;
    }

    .form-3 {
        width: 32%;
    }

    .form-3-only {
        width: 32%;
    }
}