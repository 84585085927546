@use 'styles/main';

.mat-raised-button.mat-accent, .mat-mini-fab.mat-accent {
    color: #fff;
}

.mat-bottom-sheet-container {
    background: #F3F5FD
}

.iti {
    width: 100%;
    display: inline-flex;
}

.input-tel {
    border-width: 0 0 1px 0;
    border-color: rgba(0, 0, 0, 0.42);
    border-style: solid;
    padding-top: 0.438rem;
    padding-bottom: 0.438rem;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);

    &:hover, &:focus {
        border-width: 0 0 2px 0;
        border-color: rgba(0, 0, 0, 0.87);
    }
}

.iti .dropdown-menu.country-dropdown {
    width: 18.75rem;
}

.iti input#country-search-box {
    height: 1.875rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
}

.iti .iti__country-name, .iti .iti__dial-code {
    font-size: 0.875rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
    border-width: 0 0 2px 0;
    border-color: #D54E0C;
    color: #D54E0C;
}

ngx-intl-tel-input.ng-invalid.ng-touched input::placeholder {
    color: #D54E0C;
}

.invalid-intl {
    color: #D54E0C;
    font-size: 10.5px;
    font-weight: 400;
}
.mb-80{
    margin-bottom: 80px;
}
.orange{
    color: #FFA500;
}
