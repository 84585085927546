@import '../base/colors.scss';

/* .text-primary {
    color: $primary;
} */

.text-accent {
    color: $accent;
}

.text-warn {
    color: $warn;
}

.text-white {
    color: $white;
}

.text-black {
    color: $black;
}

.text-white-light {
    color: $white-light;
}

.text-white-dark {
    color: $white-dark;
}

.text-black-light {
    color: $black-light;
}

.bg-primary {
    background-color: $primary;
}

.bg-accent {
    background-color: $accent;
}

.bg-warn {
    background-color: $warn;
}

.bg-white {
    background-color: $white;
}

.bg-white-dark {
    background-color: $white-dark;
}

.bg-primary-gradient {
    background: $primary-gradient;
}