.vh-100 {
    height: 100vh;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-46 {
    width: 46%;
}

.w-30 {
    width: 30%;
}

.h-auto {
    height: fit-content;
}

.h-100 {
    height: 100%;
}

.py-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

/* .font-45 {
    font-size: 2.813rem !important;
}

.font-32 {
    font-size: 2rem !important;
}

.font-28 {
    font-size: 1.75rem !important;
}

.font-24 {
    font-size: 1.5rem !important;
}

.font-18 {
    font-size: 1.125rem !important;
}

.font-12 {
    font-size: 0.75rem !important;
} */

.font-40 {
    font-size: 2.5rem !important;
}

.font-32 {
    font-size: 2rem !important;
}

.font-28 {
    font-size: 1.75rem !important;
}

.font-24 {
    font-size: 1.5rem !important;
}

.font-20 {
    font-size: 1.25rem !important;
}

.font-16 {
    font-size: 1rem !important;
}

.font-12 {
    font-size: .75rem !important;
}

.font-10 {
    font-size: .625rem !important;
}

.font-8 {
    font-size: .5rem !important;
}

.font-4 {
    font-size: .25rem !important;
}

.d-icon-2 {
    transform: scale(1.9) !important;
}

.icon-6 {
    width: 64px !important;
    height: 64px !important;
    font-size: 64px !important;
}

.icon-size-20 {
    width: 19px !important;
    height: 19px !important;
    font-size: 19px !important;
}