@import 'base';
@import 'elements';
@import 'helpers';

html, body {
    height: 100%;
    margin: 0;
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    background: $white;
    color: $black;
}

.mat-form-field {
    width: 100%;
}

.h-map {
    height: 350px;
}

@media (min-width: 768px) {
    .h-map {
        height: 482.06px;
    }
}
/* @media (min-width: 1200px) {
    html, body {
        font-size: $base-font-size-responsive;
    }
} */